.baseInput {
  padding: 1rem;
  color: #1B1B1E;
  border: 1px solid #C0C0C0;
  border-radius: 8px;
  width: 100% !important;
}
.baseInput:focus {
  border-color: #493EE0;
}
.dropdown {
  outline: none;
  position: relative;
  width: 38px;
  height: 100%;
  padding: 0 0 0 8px;
  border-radius: 3px 0 0 3px;
}