.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
}
.container > * {
  width: 100%;
}
.container header {
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: .8rem;
  padding: 0 1rem;
}
.container header .logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  font-weight: 900;
  font-size: 1rem;
}
.container header .logo span {
  margin-left: .5rem;
}
.container header button {
  background: transparent;
  border: none;
}
.container footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  font-size: .8rem;
  color: #c0c0c0
}
.loginContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  padding: 1rem;
  font-size: 1rem;
  color: #000;
}
.loginContainer p, .loginContainer h2{
  text-align: center;
}
.loginContainer p {
  color: #858789;
  margin-bottom: 32px;
}
.loginContainer button:first-of-type {
  margin-bottom: 10px;
}
.loginContainer button {
  width: 100%;
}
.loginContainer a {
  margin: .25rem 0 .25rem 0;
}
.anchor {
  color: rgb(15.6%, 30.3%, 78.8%)
}
