.baseButton {
  background-color: rgba(0,0,0,0.7);
  color: rgba(255,255,255,0.9);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  text-decoration: none;
  padding: 1rem;
}
.primary {
  background-color: #493EE0;
  color: #fff;
}
.secondary {
  background-color: #E6E5FB;
  color: #493EE0;
}
.disabled {
  background-color: #ECECEC;
  color: #C4C4C4;
}
.danger {
  color: #EE4266;
  background-color: #FEF6F7;
}